<template>
  <!-- 三维片 -->
  <div class="video">
    <div class="video-panel">
      <video
        id="player"
        class="video-player"
        playsinline="true"
        webkit-playsinline="true"
        preload
        x5-video-player-type="h5"
        x5-video-orientation="portrait"
        src="https://dotway.oss-cn-beijing.aliyuncs.com/ncjf/video.mp4"
        poster="../assets/images/video/pic.jpg"
        @click="play()"
      >
        你的浏览器不支持视频播放
      </video>
      <div v-if="!isPlay" class="play_btn" @click="play()"></div>
    </div>
    <div class="txt"></div>
    <div class="tips"></div>
  </div>
</template>
<script>
export default {
  name: "Video",
  data (){
		return{
      isPlay: false,
      myVideo: null
		}
  },
  mounted(){
    this.myVideo = document.getElementById("player")
    this.myVideo.addEventListener('play',()=>{
      console.log("开始播放")
      this.isPlay = true
      this.$parent.isplay = false
      this.$parent.$refs.audio.pause()
    })
    this.myVideo.addEventListener('pause',()=>{
      console.log("播放暂停")
      this.isPlay = false
    })
    this.myVideo.addEventListener('ended',()=>{
      console.log("播放结束")
      this.isPlay = false
    })
  },
  methods:{
    play(){
      // console.log(this.$parent)
      if(this.isPlay){
        this.myVideo.pause()
      }else{
        this.myVideo.play()
      }
    }
	}
}
</script>

<style lang="scss">
@import "@/assets/styles/common.scss";
.video{
    position: absolute;
    @include bg('video/bg.jpg',100%,100vh,top center);

    .video-panel{
      position: absolute;
      width: 100%;
      top: 35vh;
      
      .video-player{
        width: 100%;
      }

      .play_btn{
        position: absolute;
        @include bg('video/play_btn.png',70px,70px);
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }

    .txt{
      position: absolute;
      @include bg('video/t1.png',158px,12px);
      left: 50%;
      bottom: 150px;
      transform: translateX(-50%);
    }
    .tips{
      position: absolute;
      @include bg('video/t2.png',324px,22px);
      left: 50%;
      bottom: 100px;
      transform: translateX(-50%);
    }
}
@media screen and (max-height: 603px) {
  .video{
    @include bg('video/bg.jpg',100%,100vh,center -50px);

    .video-panel{
      top: 30vh;
    }
  }
}
</style>
